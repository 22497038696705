body {
    font-family: "Poppins" !important;
}

/* Remove default appearance */
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    background: transparent;
    /* Make the background transparent to see the custom track */
    cursor: pointer;
}

/* Style the track */
input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #d9dbe0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

/* Style the filled portion of the track */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #ba4ffa;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    margin-top: -7px;
    /* Center the thumb on the track */
}

/* Firefox */
input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #d9dbe0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #ba4ffa;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    margin-top: -7px;
    /* Center the thumb on the track */
}

/* Filled portion for Firefox */
input[type="range"]::-moz-range-progress {
    background: #ba4ffa;
}

/* IE */
input[type="range"]::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #d9dbe0;
    border: 1px solid #ccc;
}

input[type="range"]::-ms-fill-lower {
    background: pink;
    border: 1px solid #ccc;
    border-radius: 5px;
}

input[type="range"]::-ms-fill-upper {
    background: #ddd;
    border: 1px solid #ccc;
    border-radius: 5px;
}

input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #ba4ffa;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    margin-top: -7px;
    /* Center the thumb on the track */
}

.rdrDayNumber {
    color: #000;
    font-family: "Poppins";
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.rdrStartEdge,
.rdrInRange,
.rdrEndEdge {
    background-color: #434ce6;
}

.rdrDay {
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.rdrStaticRangeLabel {
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.rdrStaticRange {
    border: none;
    margin: 3px 0px;
}

.rdrStaticRangeSelected {
    color: #8b66dd !important;
}

.logout-border {
    border-radius: 8px;
    border: 1.5px solid var(--grey-300, #c2c5ce);
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
}

.login-border {
    border-radius: 8px;
    border: 1px solid var(--primary-500, #670d95);
    background: var(--primary-500, #670d95);
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
}

.invalid-feedback {
    display: none !important;
    border: none !important;
}

.login-text {
    color: #000;
    text-align: left;
    font-family: "Poppins";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;
}

.copyright-style {
    color: var(--grey-600, #4e5462);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
    bottom: 0;
    position: fixed;
    padding-bottom: 32px;
    padding-left: 32px;
}

.icon-pass {
    width: 40px;
    border-top: 1px solid var(--grey-100, #e7e9ec);
    border-bottom: 1px solid var(--grey-100, #e7e9ec);
    border-left: none !important;
    border-right: 1px solid var(--grey-100, #e7e9ec);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: none !important;
    border-top-left-radius: none !important;
    text-align: center;
}

.equal-data {
    color: var(--Warning-300, #fec84b);

    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    letter-spacing: 0.2px;
}

.RSPBprogressBar {
    height: 4px !important;
}

.error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #eb3221 !important;
}

.inputbox-border {
    border-radius: 8px;
    border: 1px solid var(--grey-100, #e7e9ec);
    background: #fff;
}

.btn-border {
    border-radius: 8px;
    border: 1.5px solid var(--Secondary-Grey-Blue-200, #dadbea);
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

::placeholder {
    color: #9da3b0 !important;
}

.placeholderColor {
    color: #9da3b0 !important;
}

.required::after {
    content: " *";
    color: black;
}

.email-border:focus {
    outline: none !important;
}

.show-pass {
    // border: none !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: none !important;
    border-top-right-radius: none !important;
    border-radius: none !important;
    border-top: 1px solid var(--grey-100, #e7e9ec);
    border-bottom: 1px solid var(--grey-100, #e7e9ec);
    border-left: 1px solid var(--grey-100, #e7e9ec);
    border-right: none !important;
    background: #fff;
}

.show-pass:focus {
    outline: none !important;
    background-color: none !important;
    border-top: 1px solid var(--grey-100, #e7e9ec) !important;
    border-bottom: 1px solid var(--grey-100, #e7e9ec) !important;
    border-left: 1px solid var(--grey-100, #e7e9ec) !important;
    border-right: none !important;
}

#sidebar-wrapper {
    min-height: 100vh;
    position: relative;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

#sidebar-wrapper .list-group {
    font-size: large;
}

.remove-pointer {
    cursor: default !important;
}

.calendersett {
    width: 100px;
    height: 100px;
    margin-bottom: 90px;
    padding-bottom: 100px;
}

/* Add animation for inline and down motion */
@keyframes inlineDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Add animation for letter movement */
@keyframes letterMove {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.animated-text {
    animation:
        inlineDown 1s ease forwards,
        letterMove 1s ease forwards;
    opacity: 0;
    display: inline-block;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.text-footer-sidebar {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 8.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 188.235% */
    letter-spacing: 0.1px;
    margin-bottom: 24px;
}

.text-footer-sidebar-open {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 160% */
    letter-spacing: 0.1px;
    margin-bottom: 24px;
}

#page-content-wrapper {
    min-width: 100vw;
}

.sticky-top {
    position: fixed;
    top: 0;
    width: 70%;
    z-index: 1;
    left: 1;
    display: flex;
}

.sticky-nav {
    position: fixed;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

.hide-btn {
    margin-right: 75px;
    margin-left: 0;
}

.mobile-first-org-nav {
    z-index: 1 !important;
}

.collapse-menu {
    margin-top: 50px;
    position: fixed;
    bottom: 0;
    margin-left: 150px;
    margin-bottom: 20px;
}

.collapsed {
    margin-left: 60px;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-spinners .loading-spinner {
    width: 3rem;
    height: 3rem;
}

.paginator-parent .react-bootstrap-table-pagination .pagination {
    position: fixed;
    bottom: 14px;
    right: 0;
}

.react-bootstrap-table-pagination .pagination {
    display: flex !important;
    justify-content: flex-end !important;
    margin-bottom: 5px !important;
    margin-right: 1rem !important;

    li a {
        background-color: white;
        /* Change to your desired background color */
        color: #9da3b0;
        /* Change to your desired text color */
        border: none;
        border-radius: 100% !important;
        /* Change to your desired border radius */
    }

    li.active a {
        background-color: #e7e9ec;
        /* Change to your desired background color */
        color: #9da3b0;
        /* Change to your desired text color */
        border: none;
        border-radius: 100% !important;
        /* Change to your desired border radius */
    }
}

.reset-button {
    color: #3a3e66;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.nav-width {
    width: 208px;
}

.nav-selected {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-selected::before {
    content: "";
    background-color: white;
    position: absolute;
    border-radius: 0.6rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.38;
}

.nav-selected h1 {
    position: relative;
    color: #ffffff;
    font-size: 14rem;
    line-height: 0.9;
    text-align: center;
}

.nav-deselect {
    margin-top: 32px;
    color: var(--primary-100, #f4e2fc);

    /* Subtitle 2 */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.1px;
}

.page-setting {
    position: fixed;
    top: 180px;
    right: 0;
}

.app-sidebar {
    width: 208px;
    padding: 0px 16px;
}

.app-sidebar-width {
    width: 208px;
}

.app-sidebar-collapsed {
    width: 80px;
}

.dropbtn {
    background-color: white;
    padding: 10px;
    font-size: 16px;
    width: 150px;
    border: white;
    cursor: pointer;
}

.nav-footer {
    .inline-text {
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
    }
}

.line-sidebar {
    height: 0px;
    align-self: stretch;
    stroke-width: 0.5px;
    stroke: var(--primary-400, #ba4ffa);
}

.select {
    background-color: rgba(#670d95, 0.5);
    padding: 3px 3px;
    border: 1px solid #670d95;
    border-radius: 4px;
    color: white;

    &:hover {
        background-color: #670d95 !important;
        border: 1px solid #670d95;
    }

    &:focus {
        box-shadow: none !important;
        outline: none;
        background-color: #670d95 !important;
    }
}

.mini-map-expanded {
    width: 1100px;
    height: 800px;
    z-index: 1000;
}

.main {
    padding-top: 1rem;
    padding-right: 220px;
    margin-left: 240px;
    transition:
        margin-rigth 0.3s,
        margin-left 0.3s;
    min-width: 100vw;
    // @media screen and (max-width: 786px) {
    //     z-index: -1;
    // }
}

@media only screen and (min-width: 1700px) {
    .mini-map-expanded {
        width: 1530px;
        height: 800px;
        z-index: 1000;
    }
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0 !important;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

input[type="color"] {
    margin-right: 8px;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;

    &::-webkit-color-swatch-wrapper {
        padding: 0;
        width: 14px;
        height: 14px;
    }

    &::-webkit-color-swatch {
        border: 1px solid #bfc9d9;
        border-radius: 4px;
        padding: 0;
    }
}

.public-sidebar {
    margin-left: 32%;
}

.logo-container {
    margin-right: 8rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.customContainer {
    display: flex;
}

.scrollbar-none {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.map-position {
    margin-left: 25%;
    margin-top: 50px;
}

.model-position {
    top: 100px;
    z-index: 10040;
    width: 100%;
}

.small-modal {
    top: 200px;
    z-index: 10040;
    width: 200px;
}

.modal-body {
    max-height: 580px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.modal-body::-webkit-scrollbar {
    display: none;
}

.col-width {
    width: 400px !important;
    height: 400px !important;
}

.margin-gap {
    margin-top: 30px;
}

.title-time {
    white-space: nowrap;
    word-break: break-all;
    overflow-wrap: break-word !important;
    font-size: 0.9rem !important;
    align-self: stretch;
}

.box {
    position: relative;
    height: 150px;
    display: flex;
    flex-direction: column;
}

.check-button {
    width: 50px;
    height: 30px;
    text-align: left;
    padding-left: 10px;
}

.box1 {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 58px;
    margin-top: 60px;
}

.box2 {
    position: absolute;
    top: 0;

    margin-top: 80px;
    margin-bottom: 100px;
}

.modal-margin {
    margin-top: 100px;
}

.popover {
    z-index: 1000;
    // margin-top: 55px;

    .arrow {
        margin-bottom: 24px;
    }
}

.add-value {
    margin-left: 150px;
}

.top-margin {
    margin-top: 10px;
}

.colo-input {
    border: none;
    width: 100%;
    font-size: 14px;
}

.leaflet-container {
    width: 100%;
    height: 100vh;
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}

.icon-width {
    width: 42%;
}

.collapse-space {
    margin-left: 112px;
    padding-right: 100px;
}

.public-sidebar-width {
    width: 400px;
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .collapse-menu {
        margin-left: 200px;
    }

    .collapsed {
        margin-left: 80px;
    }

    .app-sidebar-width {
        width: 208px;
    }

    .collapse-title {
        width: 88%;
    }

    .app-sidebar {
        width: 208px;
    }

    .app-sidebar-collapsed {
        width: 80px !important;
    }

    .public-sidebar-width {
        width: 400px;
    }
}

@media only screen and (min-width: 1800px) {
    .col-xl-2 {
        width: 300px;
    }
}

.collapse-title {
    width: 93%;
}

.cards-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.blur {
    filter: blur(2px);
}

.info-icon {
    color: #c2c5ce;
    font-weight: solid;
}

.info-icon:hover {
    color: #d99ef7;
}

.card-fontsize-title {
    font-size: 1rem !important;
    padding-top: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 26px !important;
}

.add-scroll {
    overflow-y: auto;
    max-height: 140px;
    overflow-x: hidden;
}

.add-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.add-scroll::-webkit-scrollbar:vertical {
    width: 11px;
}

.add-scroll::-webkit-scrollbar:horizontal {
    height: 11px;
}

.add-scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
}

.card-size {
    height: 160px;
    width: 220px;
}

@media screen and (max-width: 1024px) {
    .cards-wrap .card_item {
        width: 33%;
    }

    .collapse-title {
        width: 87%;
    }
}

.team-img {
    border-radius: 50% !important;
    overflow: "hidden";
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    text-align: center;
    box-shadow:
        6px 4px 8px 9px rgba(16, 24, 40, 0.06),
        0px 4px 8px -2px rgba(16, 24, 40, 0.06);
}

.custom-box-shadow {
    border: 2px solid #d99ef7;
    -moz-box-shadow: 3px 3px 3px #e2bef5;
    -webkit-box-shadow: 3px 3px 3px #e2bef5;
    box-shadow: 3px 3px 3px #e2bef5;
}

.pull-right.img-thumb.col-md-3 img {
    object-fit: cover;
}

@media only screen and (min-width: 1500px) {
    // .collapse-space {
    //     margin-left: 135px;
    //     margin-right: 10px;
    // }
    .col-xl-2 {
        width: 300px;
    }

    // .org-sidebar {
    //     margin-left: 90px !important;
    // }

    .nav-width {
        width: 800px;
    }
}

.rdrStaticRangeLabel {
    text-transform: lowercase !important;
}

.rdrStaticRangeLabel::first-letter {
    text-transform: capitalize !important;
}

.iconBoader {
    border: none;
    box-shadow: 2px 2px 2px 1px lightgrey;
    background-color: white !important;
}

.tooltip.show {
    opacity: 1 !important;
}

.iconClicked {
    background-color: #f8ecfc !important;
}

.maptunn {
    color: #670d95;
}

.maptunn:hover {
    color: white;
    background-color: #670d95 !important;
    box-shadow: 0 0.25rem 0.55rem rgb(240 241 243 / 35%);
    transition: all 0.2s ease-out;
    border: none !important;
    border-radius: 100%;
}

.bg-meili {
    color: white !important;
    background-color: #670d95;
}

.pointPopver {
    display: flex;
    flex-direction: column;
}

.statioList {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.pointPopver :hover {
    color: white;
    background-color: #ffbe2e;
    border: none !important;
}

#scroll-badge {
    animation: moveSlideshow 7s linear infinite;
}

@keyframes moveSlideshow {
    20% {
        transform: translateX(0%);
    }

    40% {
        transform: translateX(-10%);
    }

    80% {
        transform: translateX(-10%);
    }

    100% {
        transform: translateX(0%);
    }
}

#scroll-badge-long {
    animation: moveSlider 8s linear infinite;
}

@keyframes moveSlider {
    20% {
        transform: translateX(0%);
    }

    40% {
        transform: translateX(-40%);
    }

    80% {
        transform: translateX(-40%);
    }

    100% {
        transform: translateX(0%);
    }
}

.task-page-min-width {
    min-width: 196px;
    max-width: 200px;
}

//task page map align with cards above
div.mb-3.px-0.col div.mb-4.mt-2.mr-4.pr-3.row {
    padding-right: 0px !important;
    margin-left: 0px;
    margin-right: 0px !important;
}

//move modal on task page for new task, new mission and settings up
.modal-dialog.model-position.task-page.modal-md.modal-dialog-centered {
    top: 0;
}

.text-title {
    color: var(--grey-900, #24272d);
    /* Small 1 */
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    margin-left: 30px;
    margin-top: 10px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.2px;
}

.text-value {
    color: var(--grey-700, #3d414c);
    font-family: "Poppins";
    font-size: 52px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    letter-spacing: 0.2px;
    margin-left: 10px;
    text-align: left;
}

div.ml-3.task-card-div {
    max-height: 100%;
    position: absolute;
    width: 75%;
    z-index: 100;
    left: 8%;
}

.veh-cat {
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
    margin-bottom: 20px;
}

.checkbox-container {
    display: flex;
    padding: 20px 5px 10px 5px;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    gap: 30px;
    margin-right: 100px;
    border-radius: 8px;
    background: var(--base-white, #fff);
    /* S */
    box-shadow:
        0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.view-group {
    border-radius: 8px;
    border: 0.5px solid var(--grey-100, #e7e9ec);
    background: var(--grey-50, #f9fafb);
    padding-bottom: 50px;
    padding-right: 10px;
    height: 44px;
}

.view-btn {
    color: #4e5462;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;

    background-color: #f9fafb;
    border: none;
}

.view-btn:hover {
    border-radius: 8px;
    background-color: #ffffff;
    color: #2f333b;
}

.view-btn-focus {
    border-radius: 8px;
    background-color: #ffffff;
    color: #2f333b;
}

.add-icon {
    width: 24px;
    height: 24px;
}

.dash-cust {
    color: var(--grey-600, #4e5462);
    text-align: center;
    /* Small 1 */
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    letter-spacing: 0.2px;
}

.dash-button {
    border-radius: 16px;
    background: var(--grey-50, #f9fafb);
    color: var(--grey-600, #4e5462);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.card-height {
    height: 370px;
}

.icon-btn {
    margin-right: 600px;
}

.dash-button:active {
    background: #ffffff !important;
}

.bg-color {
    background-color: #e7e9ec;
}

.page-setting-card {
    padding: 16px 24px;
    border: 1px solid var(--grey-100, #e7e9ec);
    background: #fff;
}

.card-border {
    border: 1px solid var(--grey-100, #e7e9ec);
    border-radius: 8px;
    background: #fff;
    box-shadow:
        0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.sortableHelper {
    z-index: 9999;
}

.flex-card {
    display: flex;
    padding: 12px 10px;
    align-items: flex-end;
    gap: 68px;
    flex: 1 0 0;
    height: 85%;
}

.card-box.shadow-none.border-0.map-detail {
    width: 11rem;
}

.icon-border {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #fff;
    box-shadow:
        0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.page-setting-card {
    margin-top: 8.2rem;
    margin-right: 3rem;
    border: 1px solid var(--grey-100, #e7e9ec);
    background: #fff;
    width: 500px;
    border-radius: 8px;
}

.custmText {
    color: var(--grey-900, #24272d);
    text-align: left;
    /* Subtitle 1 */
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.rdrDateDisplayWrapper {
    display: none;
}

.card-body-setting {
    display: flex;
    padding-top: 16px;
    width: 392px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.card-title {
    color: var(--grey-600, #4e5462);
    text-align: right;
    /* Body 1 */
    font-family: "Poppins";
    padding-left: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.1px;
}

.dash-cards-setting {
    border-radius: 8px;
    width: 340px;
    margin-bottom: 16px;
    height: 40px;
    border: 1px solid var(--grey-100, #e7e9ec);
    background: #fff;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.modal-title {
    color: var(--grey-900, #24272d);
    /* Subtitle 1 */
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.mission-success {
    flex: 0 0 99% !important;
    max-width: 99% !important;
}

.fixed-width {
    width: 84% !important;
}

.grid-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
}

.top-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    width: 100%;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
}

.dash-cards:hover {
    box-shadow:
        0 4px 10px rgba(0, 0, 0, 0.16),
        0 4px 10px rgba(0, 0, 0, 0.23);
}

.notification-title {
    color: #3d414c;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.unread {
    color: #3d414c;
    display: inline-block;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.read {
    color: #656d7e;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.top-border {
    border-bottom: 1px solid var(--grey-100, #e7e9ec);
}

.notidication-card-border {
    padding: 16px 24px;
    border-bottom: 1px solid var(--grey-100, #e7e9ec);
}

.notifi-card {
    position: absolute;
    right: 0;
    top: 0;
    height: 35.5rem;
    width: 18rem;
    margin-top: 140px;
    border: 1px solid var(--grey-100, #e7e9ec);
    border-radius: 8px;
    background: #fff;
    margin-right: 120px !important;
}

.view-small {
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    letter-spacing: 0.2px;
}

.noti {
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

Button#first-not-recording.btn.btn-secondary,
Button#not-recording.btn.btn-secondary,
Button#recording.btn.btn-secondary {
    display: flex;
    margin: auto;
    margin-top: 25px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: darkred;
}

Button#not-recording.btn.btn-secondary {
    animation: square-to-circle 0.5s;
}

Button#first-not-recording.btn.btn-secondary,
Button#not-recording.btn.btn-secondary {
    border: 5px solid white;
    outline: 1px solid black;
}

div#record-square {
    display: none;
    z-index: 10;
    background-color: red;
    width: 15px;
    height: 15px;
    animation: circle-to-square 1s;
}

Button#recording.btn.btn-secondary {
    padding: 15px;
    border: 1px solid black;
    background-color: white !important;
    justify-content: center;
    align-items: center;
    position: relative;

    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes circle-to-square {
    0% {
        border-radius: 100% 100% 100% 100%;
        background: darkred;
        transform: scale(2.8);
    }

    100% {
        border-radius: 0 0 0 0;
        background: red;
        transform: scale(1);
    }
}

@keyframes square-to-circle {
    0% {
        border-radius: 0 0 0 0;
        background: red;
        transform: scale(0.33);
    }

    100% {
        border-radius: 100% 100% 100% 100%;
        background: darkred;
        transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
    }

    65% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
    }

    90% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
    }
}

#slide-down-div {
    display: none;
    position: absolute;
    width: 70%;
    height: 50px;
    background: green;
    opacity: 0;
    animation: slide 4s;
    color: white;
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    z-index: -10;
    left: 0;
    right: 0;
    text-align: center;
}

@keyframes slide {
    0% {
        z-index: 100;
        top: -35%;
        opacity: 0.35;
    }

    50% {
        z-index: 100;
        opacity: 1;
        top: -20%;
    }

    90% {
        z-index: 100;
        opacity: 1;
        top: -20%;
    }

    99% {
        z-index: 100;
        opacity: 0;
        top: -15%;
    }

    100% {
        z-index: -10;
        top: -15%;
        display: none;
    }
}

#spinner-recording-div {
    left: 42.5%;
    position: absolute;
    top: 68.7%;
    background-color: #fff;
    width: 70px;
    height: 70px;
    display: flex;
}

.m-auto.recording-spinner.spinner-border-md.spinner-border {
    width: 3rem;
    height: 3rem;
    color: darkred;
}

.text-center.mx-auto.notification.position-fixed.alert.alert-danger.fade.show,
.text-center.mx-auto.notification.position-fixed.alert.alert-success.fade.show {
    width: 30%;
    z-index: 1000000;
    left: 0%;
    right: 0%;
    top: 0%;
    margin-top: 8rem;
    animation: fadeInAndOut 3s linear;
}

@keyframes fadeInAndOut {
    0% {
        opacity: 0;
        top: -5%;
    }

    10% {
        opacity: 1;
        top: 0%;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

//Makes the disabled red in the PathForm.js
.DS1:disabled,
.DS2:disabled {
    color: #d8d6d6;
}

.my-2.pull-right.btn.btn-outline-primary.btn-sm {
    width: 8.9rem;
    padding: auto 0.3rem auto 0.3rem;
}

.battery-metric {
    margin-top: 0.92rem;
    margin-left: -4.2rem;
    color: #666;
    user-select: none;
}

.dimension-metric {
    margin-left: -3rem;
    color: #666;
    user-select: none;
}

#permission-picture {
    position: absolute !important;
    transform: translate(10px, -180px) !important;
    width: 90% !important;
    z-index: 1 !important;
    border-radius: 1% !important;
    display: none;
}

.prompt-modal {
    max-width: 400px;
    top: 30%;
    transform: translateY(-50%);
    z-index: 10040;
    margin: auto;
}

.prompt-title {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
}

.prompt-information {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.prompt-modal-footer {
    display: flex;
    align-items: center;
    padding: 24px;
    grid-gap: 12px;
    justify-content: center;
}

.prompt-modal-footer-accept-button,
.prompt-modal-footer-decline-button,
.prompt-modal-footer-accept-button-danger {
    width: 200px;
    height: 40px;

    box-shadow: 0px 2px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}

.prompt-modal-footer-accept-button,
.prompt-modal-footer-accept-button:active {
    background: #670d95 !important;
    border: 1px solid #670d95 !important;
    color: white !important;
}

.prompt-modal-footer-accept-button-danger {
    background: #d92d20 !important;
    border: 1px solid #d92d20 !important;
    color: white !important;
}

.prompt-modal-footer-accept-button-danger:active,
.prompt-modal-footer-accept-button-danger:hover {
    background: #f04438 !important;
    border: 1px solid #f04438 !important;
    color: white !important;
}

.prompt-modal-footer-decline-button {
    background: #f9f5ff;
}

.upload-page {
    margin-top: 15px;

    @media screen and (min-width: 1600px) {
        margin-top: -12px;
    }
}

#prompt-modal-body {
    white-space: pre;
}

// div.modal.fade.show {
//     background-color: white !important;
// }

.new-modals {
    font-family: Poppins;
    overflow-x: hidden;
    overflow-y: auto;
}

.modals-header {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.modals-labels {
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.modals-dropdown {
    border: 1px solid var(--grey-100, #e7e9ec);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 16px;
    height: 40px;
    width: 100%;
    background: white;
    color: #3d414c;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modals-inputs {
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--grey-100, #e7e9ec);
    background: #fff;
}

.modals-save-btn {
    padding: 6px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--primary-500, #670d95);
    background: var(--primary-500, #670d95);
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
}

.modals-cancel-btn {
    margin-right: 12px;
    padding: 6px 14px;
    gap: 8px;
    border-radius: 8px;
    border: 1.5px solid var(--secondary-grey-blue-200, #dadbea);
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
}

.modals-delete-btn {
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
}

.modals-little-head {
    color: var(--grey-900, #24272d);

    /* Subtitle 1 */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.modals-description {
    color: var(--grey-700, #3d414c);
    /* Body 3 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.tooltip-inner {
    transition: none !important;
}

.custom-tooltip .tooltip-inner {
    max-width: 20rem;
    background-color: white;
    text-align: start;
    white-space: break-spaces;
    border-radius: 1rem;
    overflow: hidden;
}

.custom-map-tooltip .tooltip-inner {
    background-color: white;
    text-align: start;
    white-space: break-spaces;
    border-radius: 0.5rem;
    overflow: hidden;
}
.tooltip {
    pointer-events: none !important;
}
.grid-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
}

.top-items {
    display: flex !important;
    justify-content: space-between !important;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;

    @media screen and (max-width: 1100px) {
        flex-direction: column !important;
    }

    @media screen and (max-width: 599px) {
        display: flex;
        flex-direction: column !important;
    }
}

.welcome-heading {
    color: var(--grey-900, #24272d);
    /* H4 */
    font-family: "Poppins";
    padding-bottom: 16px;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 123.077% */
    letter-spacing: 0.2px;
}

.welcome-text {
    color: var(--grey-700, #3d414c);
    /* Body 3 */
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    padding-bottom: 18px;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.btngroup {
    display: flex !important;
    border-radius: 8px;
    padding: 4px 8px 4px 4px;
    border: 0.5px solid var(--grey-100, #e7e9ec);
    background: var(--grey-50, #f9fafb);
    padding: 4px 12px;
    height: 44px;

    @media screen and (max-width: 1100px) {
        margin-bottom: 10px;
        width: 500px;
        height: auto;
    }

    @media screen and (max-width: 1260px) {
        overflow: hidden !important;
    }

    @media screen and (max-width: 599px) {
        width: 300px;
        flex-direction: column !important;
    }
}

.top-btn {
    display: flex;

    @media screen and (max-width: 599px) {
        flex-direction: column !important;
        width: 300px;
    }
}

.activ-btn {
    color: var(--grey-800, #2f333b) !important;
    text-align: center;

    /* Button S/M */
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 200% */
}

.gbtn {
    color: var(--grey-600, #4e5462) !important;
    text-align: center;

    /* Button S/M */
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 200% */
}

.popover-btn {
    color: #4e5462;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 28px;
    /* 200% */
    display: flex;
    padding: 5px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &:hover {
        color: var(--grey-400, #9da3b0) !important;
        font-family: "Poppins";
        font-size: 14px;
        background-color: transparent !important;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        /* 200% */
    }
}

.card-padding {
    padding-right: 16px;
    padding-bottom: 16px;
}

.text {
    color: var(--grey-700, #3d414c);
    font-family: "Poppins";
    font-size: 52px;
    font-style: normal;
    font-weight: 500;
    line-height: 64px;
    /* 123.077% */
    letter-spacing: 0.2px;
}

.title-value {
    color: var(--grey-900, #24272d);
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    letter-spacing: 0.2px;
}

.graph-position {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.mission-success {
    flex: 0 0 99% !important;
    max-width: 99% !important;
}

.fixed-width {
    width: 84% !important;
}

.card-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap !important;
    row-gap: 16px;
    width: 100% !important;

    @media screen and (max-width: 580px) {
        flex-direction: column;
    }
}

.top-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    width: 100%;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        overflow: auto;
    }
}

.dash-cards:hover {
    box-shadow:
        0 4px 10px rgba(0, 0, 0, 0.16),
        0 4px 10px rgba(0, 0, 0, 0.23);
}

.card-container {
    display: flex;
    gap: 70px;
    padding: 12px 16px;
    border-radius: 12px;
    overflow: hidden;
}

.title-value {
    color: var(--grey-900, #24272d);
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    letter-spacing: 0.2px;
}

.small-text {
    color: var(--grey-600, #4e5462);
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2px;
}

.mission-status-card {
    display: flex;
    flex-direction: column;
    gap: 70px;
    height: 292px;
    padding: 12px 16px 16px 16px;
    background-color: #ffffff;
    border-radius: 12px;
}

.wrapper-mission {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.delivery-card {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 12px 16px 24px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.mission-success-card {
    display: flex;
    flex-direction: column;
    padding: 12px 16px 12px 16px;
    // width: 1296px;
    height: 309px;
}

.apexcharts-legend-series {
    margin-left: 0 !important;
    width: 200px !important;
}

.added-border {
    border-radius: 8px;
    border: 2px dashed var(--grey-100, #e7e9ec);
    background: #fff;
    width: 340px;
}

.add-card {
    color: var(--grey-500, #656d7e);
    text-align: right;
    /* Body 1 */
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.1px;
}

.chevron {
    border-radius: 8px 0px 0px 8px;
    background: var(--base-white, #fff);
    vertical-align: middle;
    margin-top: 25%;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid #fff !important;
    justify-content: center;
    z-index: 1;
    box-shadow:
        0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.chevron-icon {
    //margin-top: 50%;
    color: #9da3b0;
    padding-top: 8px !important;
    width: 16px;
    height: 16px;
}

.welcomStyle {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;
}

.view-setting {
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--Secondary-Grey-Blue-500, #3a3e66);
}

.apexcharts-legend {
    top: 12px !important;
    overflow: hidden !important;
}

.apexcharts-tooltip {
    background-color: #ffffff !important;
    color: #3d414c !important;
}

.dropdowntoggler {
    border-radius: 8px 8px 8px 8px;
    border: 1px solid var(--grey-100, #e7e9ec);
    background: var(--grey-50, #f9fafb);
    width: 172px !important;
}

.dropdownhover {
    &:hover {
        background-color: white !important;
    }
}

.info-text {
    padding-left: 55px;
}

// mission page group buttons

.btn-group {
    color: var(--Grey-900, #24272d);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    background-color: white;
    border: 1px solid var(--Grey-100, #e7e9ec);
}

.link-style {
    background-color: #f9effe !important; /* Light yellow background on hover */
    cursor: pointer;
    color: #670d95;
}

.link-style:hover {
    color: #370750;
    background-color: #f4e2fc;
}

// Manage organization new style starts here

.organization-navbar {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 24px 4px 24px;
    border: #e7e9ec 1px solid;
    z-index: 100;

    .org-navbar-dropdown {
        height: 40px;
        background-color: white;
        color: #000;
        margin-left: 18px;

        .dropdown-toggle {
            height: 40px;
            padding: 6px 16px;
            border-radius: 8px;
            border: 1.5px solid #e7e9ec;
            background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #e7e9ec, #e7e9ec);
            box-shadow: 0px 1px 3px 0px #1018281a;
            color: black;
            display: flex;
            gap: 8px;
            justify-content: space-between;
            align-items: center;

            &::after {
                color: #e7e9ec;
                font-size: 1.5rem;
            }
        }

        .dropdown-menu {
            border-radius: 8px;
            border: 1.5px solid #e7e9ec;
            box-shadow: 0px 1px 2px 0px #1018280f;
        }

        .dropdown-item {
            padding: 6px 16px;

            &:hover {
                background-color: #f9fafb;
            }
        }

        .dropdown-item-text {
            padding: 6px 16px;
            background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #e7e9ec, #e7e9ec);
        }

        .dropdown-item-disabled {
            padding: 6px 16px;
        }

        .dropdown-divider {
            border: 1.5px solid #e7e9ec;
        }
    }

    .org-navbar-link {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        color: #656d7e;
        display: flex;
        padding: 4px 12px;
        border-radius: 6px;
    }

    .org-navbar-link-selected {
        color: var(--Grey-700, #3d414c);
        background: var(--Grey-50, #f9fafb);
    }
}
.dash-table-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .rounded-table-container {
        width: auto;
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        border: 1px solid var(--Grey-100, #e7e9ec);
        background: #fff;
        box-shadow:
            0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        padding-bottom: 10px;
    }
}

.big-org-container {
    display: flex;
    padding: 0px 80px 30px 80px;
    flex-direction: column;
    gap: 32px;

    // Heading starts here

    .heading-container-org {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .header-text-org {
            color: var(--Grey-900, #24272d);
            font-family: Poppins;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            /* 123.077% */
            letter-spacing: 0.2px;
        }

        .small-text-org {
            color: var(--Grey-700, #3d414c);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: 0.1px;
        }
    }

    // Page buttons purple and filter here

    .org-page-btns {
        display: flex;
        padding: 6px 16px 6px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
    }

    .new-buttons-desing-org-purple {
        border: none;
        background: var(--Primary-500, #670d95);
        box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
        color: white;

        &:hover {
            color: white;
            border: none;
            border: none;
            box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
            background: #ba4ffa;
        }
    }

    // Table starts here

    .rounded-table-container {
        width: auto;
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        border: 1px solid var(--Grey-100, #e7e9ec);
        background: #fff;
        box-shadow:
            0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        padding-bottom: 10px;
    }

    .my-custom-table {
        tr:nth-child(even) {
            background: #f9fafb; // Replace with the gray color you prefer
        }

        td,
        th {
            border-left: none;
            border-right: none;
            border-bottom: 1px solid var(--Grey-100, #e7e9ec);
            border-top: 1px solid var(--Grey-100, #e7e9ec);
        }
    }

    .cell-class {
        //styleName: Body 2;
        display: flex;
        justify-content: left !important;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 500 !important;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        color: #3d414c !important;
        margin-top: 20px;
        vertical-align: middle !important;
    }

    .custom-header {
        //border: 1px solid var(--Grey-100, #e7e9ec);
        background: #f9fafb;
        display: flex;
        justify-content: left;
        color: var(--Grey-600, #4e5462) !important;
        font-family: "Poppins" !important;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500 !important;
        text-align: left;
        line-height: 18px;
        /* 150% */
        letter-spacing: 0.2px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .team-style {
        padding-left: 50px;
        text-align: center;
    }

    .medium-700 {
        color: #3d414c !important;
        font-weight: 700 !important;
    }
    .react-table-header {
        border: 1px solid var(--Grey-100, #e7e9ec);
        background: #f9fafb;
        text-align: start;
        color: var(--Grey-600, #4e5462);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 150% */
        letter-spacing: 0.2px;
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .react-table-style {
        color: var(--Grey-900, #24272d);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
    .custom-cell {
        display: flex;
        justify-content: left !important;
        color: #3d414c !important;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        margin-top: 20px;
        text-align: left;
        letter-spacing: 0.1px;
        padding-bottom: 20pxf;
    }

    .team-div-cell {
        display: flex;
        flex-direction: column !important;
        width: fit-content;
        height: 30px;
        margin-bottom: 20px;
        padding: 5px 10px;
        gap: 4px;
        color: var(--Secondary-Grey-Blue-500, #3a3e66);
        text-align: center;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
        letter-spacing: 0.2px;
        border-radius: 16px;
        background: var(--Secondary-Grey-Blue-50, #f4f4f9);
    }
    .filter-style {
        display: flex;
        width: fit-content;
        padding: 2px 8px;
        gap: 4px;
        color: var(--Secondary-Grey-Blue-500, #3a3e66);
        text-align: center;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
        letter-spacing: 0.2px;
        border-radius: 16px;
        background: var(--Secondary-Grey-Blue-50, #f4f4f9);
    }

    .action-style {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .status-cell {
        display: flex;
        width: fit-content;
        padding: 2px 10px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 16px;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.1px;
    }

    .active-status {
        color: var(--Success-800, #05603a);
        background: var(--Success-50, #ecfdf3);
    }

    .charging-status {
        color: var(--Warning-600, #dc6803);
        background: var(--Warning-50, #fffaeb);
    }

    .inactive-status {
        background: var(--Secondary-Grey-Blue-50, #f4f4f9);
        color: var(--Secondary-Grey-Blue-600, #2a2d4b);
    }
}

.filter-button-org {
    border: 1.5px solid var(--Secondary-Grey-Blue-200, #dadbea);
    background-color: #fff;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: 8px;
    color: #4e5462;
}

// Organization modals starts here

.remove-modal-org {
    width: 400px;
    // align-items: flex-start;
    // gap: 16px;
    // border-radius: 12px 12px 0px 0px;
    // background: var(--Base-White, #fff);
}

.org-modal-header {
    align-self: stretch;
    color: var(--Grey-900, #24272d);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
    letter-spacing: 0.2px;
}

.org-modal-text {
    align-self: stretch;
    color: var(--Grey-700, #3d414c);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.org-modal-btns {
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
}

.org-modal-cancel-button {
    border: 1.5px solid var(--Secondary-Grey-Blue-200, #dadbea);
    background: #fff;
    color: var(--Grey-700, #3d414c);
    margin-right: 6px;

    &:hover {
        color: var(--Grey-600, #4e5462);
        background: #fff;
        color: var(--Grey-700, #3d414c) !important;
    }
}

.org-modal-delete-button {
    border: none;
    background: var(--Error-600, #d92d20);
    color: #fff;
    margin-left: 6px;

    &:hover {
        border: none;
        background: var(--Error-600, #d92d20);
        color: white !important;
    }
}

.vehicle-modal-org {
    width: 560px;
    // align-items: flex-start;
    // gap: 16px;
    // border-radius: 12px 12px 0px 0px;
    // background: var(--Base-White, #fff);
}

.modals-new-btns {
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
}

.cancel-btn-modal,
.cancel-btn-modal:active,
.cancel-btn-modal:focus {
    border: 1.5px solid var(--Secondary-Grey-Blue-200, #dadbea);
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
    color: #4e5462 !important;

    &:hover {
        color: var(--Grey-600, #4e5462) !important;
        background: #fff;
    }
}

.light-btn-modal,
.light-btn-modal:active,
.light-btn-modal:focus {
    border: none;
    background: #f9f5ff;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
    color: #6941c6 !important;

    &:hover {
        border: none;
        color: var(--Grey-600, #6941c6) !important;
        background: #f9f5ff;
    }
}

.save-btn-modal,
.save-btn-modal:active,
.save-btn-modal:focus {
    border: none;
    background: var(--Primary-500, #670d95);
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff !important;

    &:hover {
        border: none;
        box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
        color: #fff !important;
        background: #ba4ffa;
    }
}

.new-modal-footer {
    position: sticky;
    z-index: 100;
    bottom: 0;
    padding: 24px 0px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rotated-up-arrow-modals {
    transform: rotate(90deg);
    width: 24px;
    height: 24px;
}

.rotated-down-arrow-modals {
    transform: rotate(-90deg);
    width: 24px;
    height: 24px;
}

// New org buttons they can be used in different situatuions as well modals, pages...

.add-new-temp {
    border: 1.5px solid var(--Secondary-Grey-Blue-200, #dadbea);
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
    color: #4e5462;
    border-radius: 8px;

    &:hover {
        color: #4e5462;
        background: #fff;
    }

    &:focus {
        color: #4e5462;
        background: #fff;
    }

    &:active {
        color: #4e5462;
        background: #fff;
    }
}

// buttons-container-org is page buttons container

.buttons-container-org {
    display: flex;
    padding: 32px 24px 16px 0px;
    justify-content: flex-start;
    align-items: flex-start;
}

// container is modals buttons container
.modals-container-buttons {
    display: flex;
    margin: 12px 0px;
    justify-content: flex-start;
}

// Settings page here

// Webhooks

.table-header-webhooks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 8px, 24px, 8px, 0px;
    color: #24272d;

    .card-header-title {
        font-family: Poppins;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.2px;
        align-self: right;
    }
}

.teams-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    /* adjust the gap size as needed */
}

.logo-container {
    width: 240px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #e7e9ec;
    gap: 12px;

    .click-text {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #670d95;
    }

    .format-text {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: #656d7e;
        text-align: center;
    }
}

.image-wrapper:hover .trash-icon {
    opacity: 1;
    /* Show the trash icon on hover */
}

.image-wrapper:hover .rounded-circle {
    opacity: 0.5;
    /* Apply opacity to the image container */
}

.trash-icon,
.rounded-circle {
    transition: opacity 0.3s ease;
}

/* Initial state of the trash icon should be hidden */
.trash-icon {
    opacity: 0;
}

.org-button-group {
    border-radius: 8px;
    border: 0.5px solid var(--Grey-100, #e7e9ec);
    background: var(--Grey-50, #f9fafb);
    display: flex;
    padding: 4px;
    align-items: flex-center;
    gap: 8px;

    .org-button {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        box-shadow: none;
        border: none;
        background: var(--Grey-50, #f9fafb);
        color: var(--Grey-600, #4e5462);
        text-align: center;

        /* Button S/M */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        /* 200% */
    }

    .org-button:hover,
    .org-button:active,
    .org-button:focus {
        color: var(--Grey-600, #2a2d4b);
        border: none;
        box-shadow: none;
    }

    .org-button-links {
        color: var(--Grey-600, #4e5462);
    }

    .org-button-links:hover,
    .org-button-links:active,
    .org-button-links:focus {
        color: var(--Grey-600, #2a2d4b);
    }

    .org-button-selected {
        background: var(--Base-White, #fff);

        /* S */
        box-shadow:
            0px 1px 2px 0px rgba(16, 24, 40, 0.06),
            0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    }

    .org-button-selected:hover,
    .org-button-selected:active,
    .org-button-selected:focus {
        background: var(--Base-White, #fff);
        color: var(--Grey-600, #4e5462);

        /* S */
        box-shadow:
            0px 1px 2px 0px rgba(16, 24, 40, 0.06),
            0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    }
}

.version-text {
    //styleName: Small 1;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #c2c5ce;
}

.custom-switch-form {
    &:focus {
        border-color: #b692f6 !important;
        color: #b692f6 !important;
        box-shadow: none !important;
        background-color: white !important;
        background-image: url(./Image/switch.svg) !important;
    }
}

.custom-switch-form:checked {
    &:focus {
        color: #b692f6 !important;
        background-color: #c163f1 !important;
        border-color: #b692f6 !important;
        box-shadow: none !important;
        background-image: url("./Image/whiteSwitch.svg") !important;
    }
}

.form-check-input:checked {
    color: #b692f6 !important;
    box-shadow: none !important;
    background-color: #c163f1 !important;
    border-color: #b692f6 !important;

    &:focus {
        box-shadow: none !important;
        border-color: #b692f6 !important;
    }
}

.form-check-input {
    box-shadow: none !important;
    outline: none !important;

    &:focus {
        border-color: #b692f6 !important;
    }
}

hr {
    opacity: 2 !important;
}

.search-header {
    background: #f9fafb;
    width: 100%;
    height: 64px;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
}
.search-box {
    width: 320px;
    height: 40px;
    top: 76px;
    margin: 12px 16px 12px 16px;
    padding-left: 30px;
    background-image: url(./Image/search.svg) !important;
    background-repeat: no-repeat;
    background-position: 9px center;
    border: 1.5px solid var(--Grey-100, #e7e9ec);
    border-radius: 8px;
}

.search-box:focus {
    outline: none;
    border-color: #eacafa !important;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05); /* Border color when focused */
}

.color-selector {
    display: flex;
    padding: 8px 12px;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--grey-100, #e7e9ec);
    background: #fff;
    position: relative;
    padding: 6px 32px 6px 10px;
    font-family: verdana;
    background: white;

    &::after {
        content: url(./Image/edit.svg) !important;
        width: 0;
        height: 0;
        position: absolute;
        right: 30px;
        top: 6px;
    }

    .circle {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #eee;
        margin-right: 10px;
    }

    .hidden {
        position: absolute;
        left: 0;
        opacity: 0;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }
}

.overlay {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 105px;
    transition: 1s;

    &.active {
        top: calc(-50% + 105px);
        width: 160%;
        height: 160%;
        left: -30%;
    }
}

.ag-theme-quartz {
    background-color: "#F9FAFB";
    text-align: left !important;
    --ag-header-column-separator-display: none !important;
}
.ag-root-wrapper {
    border-radius: 0px !important;
}

.ag-header-cell-label {
    justify-content: left;
    padding-left: 10px !important;
    font-family: "poppins" !important;
    border: none !important;
}
.ag-paging-row-summary-panel {
    text-align: left !important;
}
.ag-paging-panel {
    color: #4e5462;
    border-top: none !important;
    padding-top: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ag-paging-panel .ag-paging-row-summary-panel {
    display: none !important;
}

.ag-label {
    display: none !important;
    border: none !important;
}
.ag-root-wrapper {
    border-bottom: none !important;
}
.ag-paging-panel {
    display: flex;
    justify-content: center; /* Center the pagination controls horizontally */
}
.ag-paging-panel .ag-paging-page-size .ag-wrapper {
    display: none !important;
}
.ag-paging-button.ag-disabled {
    cursor: default;
    color: #e7e9ec !important;
}

.ag-paging-button {
    cursor: default;
    color: #9da3b0 !important;
}
.ag-cell {
    white-space: normal;
    word-wrap: break-word !important;
    user-select: text; /* Allow text selection */
    cursor: text;
}
.ag-header-cell {
    display: block !important; /* Ensures the header is visible */
    visibility: visible !important; /* Makes sure visibility isn't hidden */
}
@media (max-width: 600px) {
    .ag-theme-alpine {
        font-size: 12px;
        padding: 5px;
    }
}
@media (max-height: 700px) {
    .ag-theme-quartz {
        height: 500px !important;
        overflow: scroll !important;
    }
}
.ag-theme-alpine .ag-cell {
    white-space: normal; /* Enables text wrapping */
}
.ag-header-cell {
    padding: 2px !important; /* Reducing the padding inside the header cells */
}
.ag-header-cell-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis for long text */
}
.ag-cell-focus {
    outline: none !important; /* Removes the default focus outline */
    border: none !important; /* Removes any border */
}

.notification-tab {
    border-radius: 8px;
    padding: 2px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    cursor: pointer;
}

.notification-tab-selected {
    background-color: #3538cd;
    color: white;
}

.notification-tab-deselected {
    background-color: #f4f4f9;
    color: #3a3e66;
}

.user-settings-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #24272d;
}

.custom-hr {
    border: none;
    width: 100%;
    border-top: 1px solid #e7e9ec; /* Custom border */
    margin: 20px 0; /* Adjust spacing */
}

.text-marker {
    display: flex;
    width: fit-content;
    padding: 2px 10px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 16px;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.active-status-marker {
    color: var(--Success-800, #05603a);
    background: var(--Success-50, #ecfdf3);
}

.auth-code-input {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.auth-code-input input {
    width: 56px;
    height: 64px;
    padding: 20px 13px;
    font-size: 1.5rem;
    text-align: center;
    border: 1px solid #e7e9ec;
    border-radius: 15px;
    outline: none;
    transition: border-color 0.2s ease;
}

.auth-code-input input:nth-child(4) {
    margin-left: 24px; /* Adjust spacing between the two groups */
}

.auth-code-input input:focus {
    border-color: #b692f6;
}

.auth-code-input input::-webkit-inner-spin-button,
.auth-code-input input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.auth-code-input input[type='number'] {
    -moz-appearance: textfield;
}
